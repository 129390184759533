<template>
  <transition name="modalfile">
    <div class="modal-mask fixed top-0 left-0 w-full h-auto outline-none fade">
      <div
        class="
          modal-container
          w-10/12
          mt-5
          mb-10
          mx-auto
          rounded
          shadow-lg
          z-50
          h-full
          bg-white
        "
      >
        <header class="h-8 p-4 pt-4 pb-4 w-10/12 mx-auto">
          <i
            class="
              fas
              fa-times
              cursor-pointer
              fixed
              right-0
              items-center
              mt-0
              rounded-2
              mr-40
              text-black text-4xl
              z-50
            "
            @click="$emit('close')"
            style="right: -12px"
          ></i>
        </header>
        <div
          class="h-screen overflow-y-auto w-12/12 mx-auto section-content white"
        >
          <slot name="body">
            <div
              style="width: 100%; text-align: center"
              class="spin-holder"
              v-if="loading"
            >
              <i class="spinner fa fa-atom fa-spin"></i>
              <p>Loading File</p>
            </div>

            <div v-if="!displayDocument && !loading" class="p-10 w-11/12">
              <h2 class="font-bold text-xl">The document is not available</h2>
              <p class="pt-2 font-semibold">
                This selected document is not currently available.
              </p>
              <p class="pt-2">
                We are constantly aiming to improve our product and adding more
                content so please try back again later or contact the Primary PE
                Passport office for further help or information.
              </p>
            </div>


            <div class="h-full">
              <iframe
                v-if="displayDocument"
                v-bind:src="pathUrl"
                width="100%"
                height="100%"
                class="pt-8"
                @load="fileLoaded"
                ref="reference"
              />
            </div>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { fileResourceMixin } from "@/mixins/file-ResourceMixin.js";

export default {
  name: "modalfile",
  mixins: [fileResourceMixin],
  props: {
    url: { type: String, required: true },
    title: { type: String, required: false },
  },
  computed: {
    extension() {
      return this.url.split(".").pop();
    },
    isImage() {
      let imageExtensions = ["jpg", "png"];
      return imageExtensions.includes(this.extension);
    },
    titleDisplay() {
      return this.title != undefined ? this.title : "";
    },
    displayDocument() {
      if (this.loading === false && this.hasError === false) {
        return true;
      }

      return false;
    },
  },
  data: function () {
    return {
      pathUrl: "",
      loading: true,
      showModal: false,
      hasError: false,
      isPDF: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.hasError = false;
        this.getDocument(this.url)
          .then((f) => {
            var encodedUrl = encodeURIComponent(f);
            let file = (this.pathUrl =
              this.isImage == true || this.extension == "pdf"
                ? f
                : "https://docs.google.com/viewer?url=" +
                  encodedUrl +
                  "&embedded=true");

            this.isPDF = this.extension == "pdf" ? true : false;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log("ERROR", err);
          });
    },
    checkImage(ref) {
      // console.log(this.$refs.reference.$el)
      // EXIF.getData(this.$refs.reference.$el, function () {
      //     alert(this.exifdata.Orientation);
      // });
    },
    fileLoaded(e) {
      console.log(this.$refs.reference);
    },
  },
  components: {

  },
};
</script>

<style scoped>
.document {
  padding-top: -50px;
}

.modal-body {
  overflow-y: scroll;
}

.spin-holder {
  margin-top: 20%;
}
</style>
